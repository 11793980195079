.modal {
  width: clamp(30%, 568px, 100%);
  margin: auto;
  padding: 1rem 1rem;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  display: flex;
  flex-direction: column;
  background: white;
}

.modal_fullwidth {
  width: clamp(30%, 568px, 100%);
  margin: auto;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  display: flex;
  flex-direction: column;
  background: white;
}

.modal_counter {
  width: clamp(20%, 460px, 100%);

  margin: auto;
  padding: 1rem 1rem;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  display: flex;
  flex-direction: column;
  background: white;
}

.modal_header {
  border: 0px;
  padding: 0.5rem 1rem;
}

.modal_body {
  padding: 0rem 1rem;
}

.modal_body_bkash {
  padding: 0rem 1rem;
  height: 300px;
  overflow: auto;
}

.modal_footer {
  border: 0px;
  padding: 1rem 0.75rem;
  justify-content: center;
}

.modal_input {
  border-style: none;
  background-color: #f5f5f5;
  padding-left: 10px;
  height: 50px;
  width: 100%;
}

.modal_button {
  border-style: none;
  display: block;
  height: 50px;
  width: 100%;
  background-color: #1dc468;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 12px;
}

@media screen and (max-width: 480px) {
  .modal {
    margin: 0 0 !important;
    margin: 0;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .modal_fullwidth {
    margin: 0;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .modal_counter {
    margin: 0;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .modal_footer {
    justify-content: start;
  }
}
