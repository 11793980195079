.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1030;
}

@media screen and (max-width: 480px) {
  .backdrop {
    align-items: flex-end;
  }
}
