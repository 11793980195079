.for_slick_slider .items .schoolpix {
  width: 310px;
  height: 230px;
  border-radius: 12px;
}

.schoolcap {
  padding-top: 10px;
  font-weight: 400;
}

@media (min-width: 1200px) {
  .for_slick_slider .items .schoolpix {
    width: 316px;
    height: 250px;
    border-radius: 12px;
  }
}
